.banner {

  &.revamp {
    
    overflow: visible;

    .page-banner {

      position: relative;
      min-height:330px;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background: $gray-250;

      img {

        min-height: 330px;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        max-height: 100vh;
      }
    }

    @include media-breakpoint-up(lg) {

      .page-banner {

        min-height: 500px;
        max-height: 60vh;

        img {

          min-height: 500px;
          max-height: 60vh;
        }
      }
    }

    @include media-breakpoint-down(sm) {

      .carousel-item {

        img {
  
          height: 390px;
        }
      }
    }

    @include media-breakpoint-down(xs) {

      .page-banner {

        max-height: 430px;
      }
    }
  }

}

.bookig-search-holder {

  display: flex;
  flex-direction: column;
  position: absolute;
  max-width: 890px;
  width: 100%;
  top: auto;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  text-align: center;

  .booking-search {

    position: initial;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    padding: 0;
    margin: 0;

    .item {

      position: relative;

      #check-in,
      #check-out {

        &::before {

          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
        }
      }
    }
  }
  

  .booking-search-title {

    font-size: rem(24px);
    font-weight: 800;
    margin-bottom: 10px;
    text-shadow: 0 0 7px rgba($black, 0.7);
    color: $white;
  }

  .booking-search-info {

    margin-top: 10px;

    .col-info {

      position: relative;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 10px;

      &:first-child {

        span {

          &::before {

            display: none;
          }
        }
      }

      span {

        display: block;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 10px;
        text-shadow: 0 0 7px rgba($black, 0.7);
        color: $white;
        margin-bottom: 0;

        &::before {

          position: absolute;
          top: 50%;
          left: 0;
          width: 6px;
          height: 6px;
          content: '';
          border-radius: 50%;
          background: $white;
          box-shadow: 0 0 7px 1px rgba($black, 0.7);
        }
      }

      @include media-breakpoint-down(sm) {

        span {
  
          display: inline-block;
          position: relative;

          &::before {

            display: none !important;
            @include transform(translateY(-50%));
            width: 5px;
          }
        }
  
        &:first-child {
  
          span {
  
            &::before {
  
              display: block;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {

    bottom: 20px;

    .booking-search-info { 

      font-size: rem(12px);
    }
  }

  @include media-breakpoint-down(sm) {

    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    bottom: 0;
    height: 100%;

    .booking-search-title {

      font-size: rem(20px);
      margin-top: 40px;
    }

    .booking-search-info { 

      margin-top: auto;
      margin-bottom: 20px;

      .col-md { 

        margin-top: 8px;
      }
    }
    
  }
}

.booking-search-info-b {

  background: $yellow;

  .container {

    max-width: 890px;

    .col-info {

      font-size: rem(12px);
      font-weight: 700;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
    }
  }

  @include media-breakpoint-down(sm) {

    padding-top: 10px;

    .container {

      .col-info {

        padding-top: 0;
        padding-bottom: 10px;
      }
    }
  }
}

.flex-hero-banner {

  display: flex;

  .flex-hero {

    flex-grow: 1;
    flex-basis: 33.333%;
    width: 33.333%;

    img {

      height: 400px; 
      object-fit: cover;
    } 
  }
}

.hero-swiper {

  &.swiper-holder-2 {
    
    .swiper-pagination-bullet {

      margin-left: 5px !important;
      margin-right: 5px !important;
    }
  }

  .swiper-slide {

    height: 300px;
  }

  .img-objectfit {

    height: 100%;
  }

  .swiper-pagination-bullet {

    width: 12px;
    height: 12px;
    background: $gray-150;
    margin: 5px !important;

    &.swiper-pagination-bullet-active {

      box-shadow: none !important;
      background: $white;
    }
  }
}

.section-hero {

  .campaign-page & {

    min-height: 700px;

    > img {

      min-height: 700px;
    }

    @include media-breakpoint-down(sm) {

      min-height: 550px;

      > img {

        min-height: 550px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .hero-content {

        position: relative;

        img {

          width: 90px;
          margin-top: 30px;
        }
        
      }
    }

  }

  &.section-dark-overlay {

    &::after {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba($black, 0.4);
    }
    
    .hero-content {

      z-index: 2;
    }

  }

  &.section-dark-gradient {

    &::after {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 101%;
      content: '';
      @include linear-gradient(to top, rgba($black, 1) 0, rgba($black, 0) 100%);
    }
    
    .hero-content {

      z-index: 2;

      h4 {

        font-size: rem(30px);
      }
    }

    @include media-breakpoint-down(md) {
      
      .hero-content {

        h4 {

          font-size: rem(25px);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      
      .hero-content {

        h4 {

          font-size: rem(18px);
        }
      }
    }
  }

  &.fullscreen-banner {

    @include media-breakpoint-up(lg) {

      height: 100vh;

      > img {

        height: 100vh;
      }
    }
  }

  .fixed-hero {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hero-content {

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    align-items: center;
    justify-content: center;
    color: $white;
    text-align: center;

    h1 {

      font-size: rem(90px);
      text-shadow: 0 0 12px rgba($black, 0.7);
    }

    h4 {

      font-size: rem(37px);
      font-weight: 300;
    }

    &.flex-end {

      justify-content: flex-end;
      padding-bottom: 5%;
    }

    @include media-breakpoint-down(lg) {

      h1 {

        font-size: rem(60px);
      }
    }

    @include media-breakpoint-down(md) {

      padding: 20px;

      h1 {

        font-size: rem(45px);
      }

      h4 {

        font-size: rem(25px);
        font-weight: 300;
      }
    }
  } 
  
}
