// ==========================================================================
// Header Styles
// ==========================================================================
.site-header {
    padding-top: 9px;
	padding-bottom: 9px;
    padding-left: $spacer;
    padding-right: $spacer;
    transition: 1s all ease;
    background: #fff;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1020;
    box-shadow: 0 1px 5px rgba($gray-900, 0.3);
    @include flex-container( $align: center );

    &.no-shadow {

        box-shadow: none;
    }

    @include media-breakpoint-down(md) {

        padding-left: 15px;
        padding-right: 15px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 0 0 100%;
        max-width: 1350px;
        width: 100%;
    }

    &.advisory {

        p {
            font-size: $font-size-base * .7;

            strong.yellow {
                color: $yellow;
                font-weight: 600;
            }

            @include break-from( medium) {
                font-size: $font-size-base;
            }
        }
    }

    @include break-from(1299) {
        padding-left: $spacer * 2.5;
        padding-right: $spacer * 2.5;
        background: $white;
        box-shadow: 0 2px 10px rgba( 0, 0, 0, 0.1 );
    }

    &.sticky-top {
        background: $white;
        // box-shadow: 0 0 0;
        // top: 0;
    }

    &.smally {

        &.advisory {
            .travel-advisory {
                display: none;
            }
            .qr-code {
                // transition: all 1s ease;
                @include transition(all 0.5s ease);
            }
        }
	}

    .branding,
	.navbar {
	    @include flex-item();
    }

    .branding {
        flex-grow: 6;
        margin: auto;

        @include break-from( medium ) {
            flex-grow: 2;
        }

        h2 {
            padding: 0;
            margin: 0;
            line-height: 0;

            .logo {
                transition: 1s all ease;
                width: 120px;
                height: 36px;

                @include break-from( medium ) {
                    width: 160px;
                    height: 48px;
                }
            }
        }
    }

    .navbar {
        flex-grow: 6;

        @include break-from( medium ) {
            flex-grow: 10;
        }

        .sitenav {
            @include break-until( 1299px ) {
                display: none;
            }
        }
    }

    .burger-menu {
		background: transparent;
		border: 0;
		padding: 0;
		line-height: 1;
		letter-spacing: 2px;

		.bars {
			position: relative;
			width: 22px;
			// height: 36px;
			display: flex;
			margin: auto;
            flex-direction: column;
            justify-content: center;
		}

		.bar {
			width: 100%;
			border-top: 2px solid $black;
			display: block;
			background-color: $black;
			transform-origin: right center;
			transition: 0.3s;

            &:nth-child(2) {

                margin: 5px 0;
            }

			.sitenav-mobile-active & {
				opacity: 0;

				&:first-of-type,
				&:last-of-type {
					opacity: 1;
				}

				&:first-of-type {

                    @include transform(rotate(-45deg) translateX(2px));
				}

				&:last-of-type {

                    @include transform(rotate(45deg) translateX(1px));
				}
			}
		}

		@include break-from( 1300px ) {
			display: none;
		}

		.text {
			font-weight: $font-weight-bolder;
			font-size: 9px;
			line-height: 24px;
			text-transform: uppercase;
			display: inline-block;
			margin-bottom: -9px;
            color: $blue;
		}
	}
}

.sitenav-mobile {
    margin-top: 0;
    position: fixed;
    z-index: 1021;
    background: $white;
    right: 0;
    top: 0;
    width: 280px;
    max-width: 400px;
    height: calc(100%);
    overflow-x: auto;
    box-shadow: 0 2px 10px rgba( 0, 0, 0, 0.1 );
    border-radius: 20px 0 0 0;
    @include transition(all 0.3s ease);
    @include transform(translateX(101%));

    &.sticky-top {

        height: calc(100% - 4.2em);
    }

    &.expand {

        width: 90%;
    }

    &.open {

        @include transition(all 0.3s ease);
        @include transform(translateX(0));
    }

    @include break-from(1301px) {

        display: none !important;
    }

    .nav {
        flex-direction: column;
        align-items: center;
        padding-top: $spacer * .5;

        > li {
            border-bottom: 1px solid $light-gray;

            &.dropdown {

                > .dropdown-toggle {

                    &::after {

                        font-family: 'FontAwesome';
                        font-weight: 400;
                        content: '\f078';
                        display: inline-block !important;
                        margin-left: 5px;
                        font-size: 80%;
                        border: 0;
                        @include transition(all 0.5s ease);
                        vertical-align: initial;
                    }
                }

                &.show {

                    > .dropdown-toggle {

                        &::after {

                            @include transform(rotate(90deg));
                        }
                    }
                }
            }
        }

        li {
            width: calc(100% - 30px);
            position: relative;
            list-style: none;
            text-align: right;
            margin-left: 30px;

            &.active {
                a {
                    color: $black;
                }
            }

            &.active {
                > a {
                    color: $black;
                    font-weight: 600;
                    text-decoration: none;
                }
            }

            a {
                display: block;
                padding: 10px 20px;
                color: $dark-gray;
                font-size: rem(14px);
                line-height: 22px;
                border-bottom: 0;
                text-decoration: none;
            }

            &.dropdown {

                padding-left: 0;
            }

            &.hiring {

                a {

                    display: inline-block;
                    padding-right: 5px;
                }

                &::after {

                    margin-right: 20px;
                    content: '✨';
                }
            }

            .dropdown-menu {

                li {
                    margin-left: auto;
                    margin-right: 0;
                }

                a {

                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                &.show {
                    border: 0;
                    margin-left: 0;
                    padding: 0 0 ($spacer * .4) 0;
                    min-width: 100%;
                    position: relative !important;
                    transform: translate3d(0, 0, 0) !important;

                    li {
                        &.dropdown:not(.my-profile) {
                            width: 50%;
                            display: inline-block;
                            padding-right: 20px !important;
                            margin-bottom: 15px;

                            a.dropdown-toggle {
                                font-weight: 600;
                                padding-right: 0 !important;

                                &:after {
                                    display: none;
                                }
                            }

                            ul.dropdown-menu {
                                display: block;
                                position: relative;
                                min-width: 100%;
                                text-align: left;
                                padding: 0;
                                border: 0;

                                li {
                                    width: 100%;
                                    padding: 0;
                                    padding-top: 3px;
                                    padding-bottom: 3px;
                                    padding-right: 0;

                                    a {
                                        font-size: rem(13px);
                                        padding: 0;

                                        @include break-until ( small ) {
                                            // font-size: $font-size-base * 0.67;
                                            font-size: rem(13px);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    > li {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .sub-expand {

        > .dropdown-menu {

            width: 100%;
            top: 0;
            left: 0;
            padding-bottom: 15px;
            column-count: 2;
            column-gap: 10px;
            -moz-column-count: 2;
            -moz-column-gap: 10px;
            -webkit-column-count: 2;
            -webkit-column-gap: 10px;
            grid-auto-flow: row dense;

            .dropdown {

                width: 100% !important;
                -webkit-column-break-inside: avoid;
                -webkit-perspective: 1;
                page-break-inside: avoid;
                -moz-column-break-inside: avoid;
                break-inside: avoid;
                -webkit-column-span: none;
                -moz-column-span: none;
                column-span: none;
                display: inline-block;
            }
        }
    }
}

.sitenav-mobile-active {
    header {
        background: $white;
    }
}

.nav {

    li {

        &.highlights {

            a {

                position: relative;
                display: inline-block;
                font-weight: 700;
                // background: linear-gradient(90deg, #0500FF 0%, #DB00FF 100%);
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                // background-clip: text;
                // text-fill-color: transparent;
                color: $purple !important;

                &::after {

                    display: inline-block !important;
                    vertical-align: middle;
                    margin-left: 4px;
                    content: '';
                    width: 14px;
                    height: 14px;
                    background: url('../images/shine_2.png') no-repeat center;
                    background-size: contain;
                }
            }
        }

        &.highlight-text {
            a {
                font-weight: 700;
                color: $purple !important;
            }
        }
    }
}

@include break-from(1300px){
    
    .mm-logo-dark {

        display: none;
    }
}

@include break-until(1300px){

    .mm-logo-light {

        display: none;
    }

}
