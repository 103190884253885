#cookie-law-info-bar {

  width: calc(100% - 40px) !important;
  max-width: 900px;
  top: auto !important;
  bottom: 20px !important;
  left: 20px !important;
  left: 50% !important;
  margin-left: 0 !important;
  padding: 20px 25px !important;
  @include transform(translateX(-50%));
  border-radius: 15px;
  box-shadow: 0 0 8px 0 rgba($black, 0.5) !important;

  .cookie-title {

    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .cli-plugin-button {
    
    line-height: 1.2;
    padding: 12px 15px;
    border-radius: 30px;
  }

  .cli-style-v2 {

    display: flex !important;
    flex-wrap: nowrap !important;
    align-items: center;
    font-size: 13px;

    .cli-bar-message {

      padding-right: 20px;
    }

    .cli-bar-btn_container {

      margin-top: 0 !important;
    }

    @include media-breakpoint-down(sm) {

      font-size: 11px;
      line-height: normal;

      .cli-bar-btn_container {

        margin-top: 10px !important;
      }
    }

    @include media-breakpoint-down(xs) {

      flex-wrap: wrap !important;
      justify-content: center;
    }

  }

  .cli-plugin-button {

    width: 110px;
    padding: 10px 10px;
    white-space: initial;
    border: 1px solid $default-color;

    &.cli_settings_button {

      background-color: transparent !important;

    }

    @include media-breakpoint-down(sm) {

      padding: 4px;
    }
  }

  @include media-breakpoint-down(sm) {
    
    padding: 12px !important;

    .cookie-title {

      font-size: 16px;
    }
  }

}


body {

  &.cli-barmodal-open {

    overflow: initial !important;
  }
}

.cli-modal-backdrop {

  &.cli-show {

    display: none !important;
  }
}