// fonts
@font-face {
    font-family: 'moontime';
    src: url('../fonts/moontime-regular-webfont.woff2') format('woff2'),
         url('../fonts/moontime-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// @font-face { 
//     font-family: 'montserrat-extrabold';
//     src: url('../fonts/montserrat-extrabold-webfont.woff2') format('woff2'),
//          url('../fonts/montserrat-extrabold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }
  
// @font-face {
//     font-family: 'montserrat-semibold';
//     src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
//          url('../fonts/Montserrat-SemiBold.woff') format('woff'),
//          url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'montserrat-light';
//     src: url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
//          url('../fonts/montserrat-light-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'montserrat-thin';
//     src: url('../fonts/Montserrat-Thin.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'montserrat-medium';
//     src: url('../fonts/Montserrat-Medium.woff2') format('woff2'),
//          url('../fonts/Montserrat-Medium.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

@font-face {
    font-family: 'AhoyDriftwood';
    src: url('../fonts/AhoyDriftwood.woff2') format('woff2'),
        url('../fonts/AhoyDriftwood.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-base:            'Montserrat';
$headings-font-family:        'Montserrat';
$AhoyDriftwood:               'AhoyDriftwood';

$h1-font-size:                $font-size-base * 4.3;
$h2-font-size:                $font-size-base * 3.75;
$h3-font-size:                $font-size-base * 3.125;

// Variables
$breakpoints: (
	xxsmall: 0,
    xssmall: 375px,
	xsmall:  420px,
	small:   560px,
	medium:  1054px,
	large:   1126px,
	xlarge:  1200px,
	xxlarge: 1440px,
    xxxlarge: 1980px
);

$gray:   	  				#e3e3e3;
$dark-gray:	  				#6C6C6C;
$light-gray:                #F2F2F2;
$light-gray-2:              #FAFAFA;
$light-gray-3:              #F8F8F8;
$gray-50:                   #E5E5E5;
$gray-150:                  #767474;
$gray-200:                  #d0d0d0;
$gray-250:                  #BCBCBC;
$gray-300:                  #9e9d9d;
$gray-500:                  #404040;
$gray-600:                  #303030;
$gray-800:                  #191919;
$gray-900:                  #2D2D2D;
$black:  	  				#000000;
$red:    	  				#c73232;
$red-400:     				#FF6564;
$red-500:     				#FF5959;
$yellow: 	  				#ffcc64;
$blue:   	  				#338DE0;
$blue-500:    				#0072AE;
$blue-200:    				#00C0CF;
$mint-green:  				#62E2CB;
$light-black:               #545454;
$green-15:					#0E952B;
$green-25:					#5CB56A;
$green-35:					#9DFFAD;
$green-45:					#4FC48A;
$green-55:     	  	        #75DD98;
$green-60:     	  	        #8AAF3B;
$white:		  	      		#FFFFFF;
$purple:	  	      		#9C27B0;
$purple-600:	  	      	#6A5F89;
$purple-300:	  	      	#85709B;
$purple-200:	  	      	#A68BBF;
$purple-500:	       		#f3e5f5;
$default-color:	  	        #121212;
$lblue:     	  	        #4EC2C4;

$primary: 					$yellow;

$line-height-base:            1.5;

// Paragraphs
//
// Style p element.
$paragraph-margin-bottom:   $spacer * .8;

// Links
//
// Style anchor elements.

$link-color:                              $blue;
$link-decoration:                         none !default;
$link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

$cellHeight: 33px;