.mb-search-title {

  display: block;
  font-size: rem(23px);
  text-align: center;
  margin-bottom: 20px;

  @include media-breakpoint-down(xs) {

    font-size: rem(20px);
  }
}

.qr-code {

  display: block;
  position: absolute;
  width: 127px;
  height: 76px;
  left: 20px;
  bottom: -100px;
  z-index: 999;
  border-radius: 10px;
  background: #934BDB;  
  @include transform(translateX(-200px));
  @include transition(all 0.5s ease);

  &.bounceInLeft {

    @include transform(translateX(0));
    @include animation(bounceInLeft 1s 1);
  }

  a {
    
    display: flex !important;
    height: 100%;
  }

  .qr-close {

    position: absolute;
    top: -2px;
    right: 8px;
    cursor: pointer;
    color: $white;
  }

  .column {

    &.left {

      width: 76px;
      height: 100%;
      border-radius: 10px 0px 0px 10px;
      padding: 3px;
      background: rgba(253, 253, 253, 0.4);

      img {

        width: 100%;
        height: 100%;
        border-radius: 10px 0px 0px 10px;
      }
    }

    &.right {

      width: 40%;
      border-radius: 0px 10px 10px 0px;
      font-weight: 800;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 18px;
      text-align: center;
      padding-top: 1px;

      span {

        font-weight: 400;
        font-size: 14px;
        line-height: 10px;
        right: 10px;
        position: absolute;
        top: 3px;
        cursor: pointer;
      }
    }
  }

  & + .birthday-promo {

    bottom: -200px;
  }

  @include breakpoint(xl) {

    display: none !important;
  }
}

.birthday-promo {

  display: block;
  position: absolute;
  width: 127px;
  height: 75px;
  left: 20px;
  bottom: -100px;
  z-index: 999;
  border-radius: 10px;
  background: $red-400;  
  @include transform(translateX(-400px));
  @include transition(all 0.5s ease);

  &.bounceInLeft {

    @include transform(translateX(0));
    @include animation(bounceInLeft 1s 1);
  }

  a {
    
    display: flex !important;
    height: 100%;
  }

  .birthday-close {

    position: absolute;
    top: -2px;
    right: 8px;
    cursor: pointer;
    color: $white;
  }

  .column {

    &.left {

      width: 76px;
      height: 100%;
      border-radius: 10px 0px 0px 10px;
      padding: 3px;
      background: $white;

      img {

        width: 100%;
        height: 100%;
        padding: 5px;
      }
    }

    &.right {

      width: 40%;
      border-radius: 0px 10px 10px 0px;
      font-weight: 800;
      font-size: 11px;
      color: $white;
      line-height: 17px;
      text-align: center;
      padding-top: 1px;

      span {

        font-weight: 400;
        font-size: 14px;
        line-height: 10px;
        right: 10px;
        position: absolute;
        top: 3px;
        cursor: pointer;
      }
    }
  }

  @include breakpoint(xl) {

    display: none !important;
  }
}

@include keyframes(bounceInLeft){
  0%,60%,75%,90%,to{
    -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
    animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  0%{
      opacity:0;
      -webkit-transform:translate3d(-3000px,0,0) scaleX(3);
      transform:translate3d(-3000px,0,0) scaleX(3)
  }
  60%{
      opacity:1;
      -webkit-transform:translate3d(25px,0,0) scaleX(1);
      transform:translate3d(25px,0,0) scaleX(1)
  }
  75%{
      opacity:1;
      -webkit-transform:translate3d(-10px,0,0) scaleX(.98);
      transform:translate3d(-10px,0,0) scaleX(.98)
  }
  90%{
      opacity:1;
      -webkit-transform:translate3d(5px,0,0) scaleX(.995);
      transform:translate3d(5px,0,0) scaleX(.995)
  }
  to{
      opacity:1;
      -webkit-transform:translateZ(0);
      transform:translateZ(0);
  }
}

.home-revamp {
  
  padding-top: 30px !important;
  padding-bottom: 30px !important;

  h1 {
      
    font-size: rem(60px);
    font-weight: 800;
  }

  h2 {

    font-size: rem(55px);
    font-weight: 800;
    line-height: 1.1;
  }

  h3 {

    font-size: rem(40px);
    font-weight: 300;
    font-family: $font-family-base;
  }

  h4 {

    font-size: rem(20px);
    font-weight: normal;
    margin-bottom: 0;

    strong {

      font-weight: 800;
    }
  }

  .text-title {

    h2 {

      font-size: rem(35px);
      font-weight: normal;
    }
  }

  .section {

    position: relative;
    padding: 50px 0;

    .container {

      max-width: 1350px;
      width: 100%;
    }

    .container-sm {

      max-width: 640px;

      &.country-info {
        
        max-width: 850px;
      }
    }

    @include media-breakpoint-down(sm) {

      &.section-map {

        padding-bottom: 0;
      }
    }

  }

  @include media-breakpoint-down(md) {

    padding-top: 20px !important;
    padding-bottom: 20px !important;

    h1 {

      font-size: rem(40px);
    }

    h2 {

      font-size: rem(35px);
    }

    h3 {

      font-size: rem(25px);
      font-weight: 300;
      font-family: $font-family-base;
    }

    .text-title {

      h2 {
  
        font-size: rem(25px);
        font-weight: normal;
      }
    }

    .section {

      padding: 30px 0;

    }
  }

  @include media-breakpoint-down(sm) {

    padding-bottom: 0 !important;
  }
}

.flex-title {

  padding-left: 50px;
  padding-right: 50px;

  &.borderline {

    padding-bottom: 10px;
    border-bottom: 1px solid $default-color;
  }
  
  .container-fluid {

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.flex-end {

      justify-content: flex-end;
    }
  }

  h4 {

    font-size: rem(20px);
    font-weight: normal;
    margin-bottom: 0;
    margin-bottom: 10px;

    strong {

      font-weight: 800;
    }
  }
  
  @include media-breakpoint-up(xl) {

    .flex-title-ab {

      position: absolute;
      top: 0;
      left: 50%;
      @include transform(translateX(-50%));
    }
  }

  @include media-breakpoint-down(lg) {

    padding-left: 0;
    padding-right: 0;

    .flex-title-ab {

      width: auto !important;
      max-width: initial !important;
    }

    .flex-end {

      justify-content: space-between;
    }
  }

  @include media-breakpoint-down(sm) {

    h4 {

      display: none;
    }
  }

}

.des-navigation {

  position: relative;
  overflow: auto;
  padding-bottom: 10px;

  ul {

    display: flex;
    margin-bottom: 0;
    padding: 0;
  }

  li {

    list-style: none;
    margin-left: 10px;

    &.globe {

      a {

        padding-left: 10px;
        padding-right: 10px;
      }
    }

    img {

      width: 22px;
      height: 22px;
    }

    a {

      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: rem(12px);
      text-transform: uppercase;
      padding: 5px 15px;
      border-radius: 20px;
      color: $default-color;
      background: $gray-50;
      cursor: pointer;

      &.active {

        background: $yellow;
        pointer-events: none;
      }

      &:hover {
        background: $yellow;
      }
    }
  }

  &.outlined {

    li {
      
      a {

        background: none;
        border: 1px solid $default-color;

        &.active {

          font-weight: 700;
          color: $white;
          background: $default-color;
        }
      }


      &.globe {

        a {
          
          .gif-dark {

            display: block;
          }

          .gif-light {

            display: none;
          }

          &.active {

            .gif-dark {

              display: none;
            }
  
            .gif-light {
  
              display: block;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {

    margin-left: -15px;
    margin-right: -15px;
    padding-right: 15px;
    width: auto;
  }

  @include media-breakpoint-down(sm) {

    ul li {

      &:last-child {

        padding-right: 10px;
      }
    }
  }

}

.card-destination {

  display: flex;
  flex-direction: column;
  max-width: calc(100% - 16px);
  margin-left: 16px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  background: $white;
  box-shadow: 0 0 6px 0 rgba($black, 0.4);

  > a {

    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {

      .card-img {
  
        img {
  
          @include transform(scale(1.1));
        }
  
      }
    }
  }

  .card-img {

    position: relative;
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 300px;
    background: $gray-250; 

    img {

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      @include transition(all 0.5s ease);
    }

    .overlay {

      font-size: rem(40px);
      font-weight: 700;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.5);
      color: $white;
      text-align: center;
      line-height: 1.2;
      text-shadow: 0 0 5px rgba($black, 0.5);
      opacity: 0;
      @include transition(all 0.5s ease);

      &.coming-soon {

        opacity: 1;
        background: transparent;
      }

      &.country-overlay {

        font-size: rem(38px);
        text-transform: uppercase;
      }
    }

    .card-caption {

      font-size: rem(14px);
      position: absolute;
      top: 40px;
      left: 0;
      padding: 15px 20px;
      background: $red-400;
      color: $default-color;
      max-width: calc(100% - 40px);
      line-height: 1.2;

      h5 {

        font-size: rem(16px);
        font-weight: 900;
        margin-bottom: 0;
      }

      p {

        margin-bottom: 0;
      }
      
    }

    @include media-breakpoint-down(md){

      height: 210px;

      .overlay {

        font-size: rem(40px);

        &.country-overlay {

          font-size: rem(30px);
        }
      }

      .card-caption {

        font-size: rem(13px);
        top: 20px;
        max-width: calc(100% - 20px); 

        h5 {

          font-size: rem(15px);
        }
      }
    }

    @include media-breakpoint-down(sm){

      height: 160px;

      .overlay {

        font-size: rem(30px);

        &.country-overlay {

          font-size: rem(20px);
        }
      }

      .card-caption {

        font-size: rem(11px);
        padding: 10px;

        h5 {

          font-size: rem(13px);
        }
      }
    }
  }

  .card-body {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 15px;
    text-align: center;

    h3 {

      font-family: $font-family-base;
      font-size: rem(18px);
      font-weight: 700;
      color: $default-color;
      text-transform: uppercase;
      margin-bottom: 0;

      span {

        display: block;
        font-weight: normal;
      }
    }

    @include media-breakpoint-down(md) {

      h3 {

        font-size: rem(15px);
      }
    }

    @include media-breakpoint-down(sm) {

      padding: 10px;

      h3 {

        font-size: rem(12px);
      }
    }
  }

}

.swiper-holder-3,
.swiper-filter {

  position: relative;
  border-top: 1px solid $gray-250;

  .swiper-country {

    margin-left: 50px;

    + .swiper-destination {

      margin-left: 50px;
    }

    &.non-swiper-slide {

      + .swiper-destination {

        margin-left: 0;
      }
    }

    @include media-breakpoint-down(lg) {

      margin-left: 0;

      + .swiper-destination {

        margin-left: 0;
      }
    }
  }

  .swiper {

    padding-right: 50px;

    @include media-breakpoint-down(lg) {

      padding-right: 15px;
    }
  }

  .swiper-slide {

    max-width: 320px;
    height: auto;

    &:first-child {

      margin-left: 50px;
    }

    @include media-breakpoint-down(lg) {

      &:first-child {

        margin-left: 0;
      }
    }

    @include media-breakpoint-down(md) {

      width: 240px;
    }

    @include media-breakpoint-down(sm) {

      width: 200px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {

    top: calc(50% - 20px);
    right: 30px;

    &::after {

      font-size: rem(30px);
      color: $white;
      text-shadow: 0px 1px 10px $black;
    }

    &.swiper-button-disabled {

      opacity: 0;
    }

  }

  .swiper-button-prev {

    left: 30px;
  }

  @include media-breakpoint-down(md) {

    .swiper-button-next {

      right: 10px;
    }

    .swiper-button-prev {

      left: 10px;
    }
  }

  @include media-breakpoint-down(sm) {

    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination {

      display: none;
    }

    .swiper {

      padding-top: 10 !important;
      padding-bottom: 10px !important;
    }
    
  }
}

.section-grid {

  padding-left: 32px;
  padding-right: 32px;

  @include media-breakpoint-down(md) {

    padding-left: 15px;
    padding-right: 15px;
  }
}

.slider-container {

  padding: 20px 33px;
  border-radius: 10px;

  .swiper-holder-3 {

    border-color: rgba($black, 0.8);

    .swiper {

      margin-left: -33px;
      margin-right: -33px;
    }

    .swiper-slide {
  
      &:first-child {
  
        margin-left: 23px;
      }
  
      &:last-child {
  
        margin-right: 23px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {

      right: 20px;
    }

    .swiper-button-prev {

      left: 20px;
    }
  }

  &.green {

    background: rgb(75,111,219);
    background: linear-gradient(342deg, rgba(75,111,219,1) 0%, rgba(117,221,152,1) 100%);
  }

  &.orange {

    background: rgb(255,101,100);
    background: linear-gradient(342deg, rgba(255,101,100,1) 0%, rgba(255,204,100,1) 100%);

    .card-destination {

      .card-img {

        height: calc(300px + 78px);
        border-radius: 20px;

        img {

          border-radius: 20px;
        }

        @include media-breakpoint-down(md) {

          height: 276px;
        }

        @include media-breakpoint-down(sm) {

          height: 227px;
        }

      }
    }
  }

  @include media-breakpoint-down(md) {

    padding-left: 15px;
    padding-right: 15px;

    .swiper-holder-3 {

      .swiper {

        margin-left: -15px;
        margin-right: -15px;
      }

      .swiper-slide {
    
        &:first-child {
    
          margin-left: 0;
        }
    
        &:last-child {
    
          margin-right: 0;
        }
      }
    }

  }

}

.readmore-content {

  overflow: hidden;
  display: -webkit-box;
  line-clamp: 6;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  height: 144px;

  &.show {

    overflow: visible;
    display: -webkit-box;
    line-clamp: initial;
    -webkit-line-clamp: initial;
    -webkit-box-orient: vertical;
    height: auto;
  }
  
  img {

    margin-top: 30px;
    margin-bottom: 20px;
  }

}


.readmore-btn {

  display: inline-block;
  margin-top: 20px;
  font-size: rem(20px);
  font-weight: 900;
  color: $default-color;
}

.card-trip {

  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 700px;
  margin: 0 15px;
  border-radius: 20px;
  // height: 100%;
  @include transition(all 0.5s ease);

  .card-img {
    
    border-radius: 20px;
  }

  img {

    border-radius: 20px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .stretched-link {
      
    &::after {

      z-index: 5;
    }
  }

  .card-trip-caption {

    position: relative;
    font-size: rem(14px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: -60px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: inherit;
    background: $white;
    box-shadow: 0 0 5px rgba($black, 0.5);
    padding: 15px;
    text-align: center;
    z-index: 2;

    h3 {

      font-size: rem(27px);
      font-weight: 900;
      margin-bottom: 10px;
    }

    p {

      margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {

      h3 {

        font-size: rem(20px);
      }
    }

    @include media-breakpoint-down(sm) {

      font-size: rem(12px);
      margin-top: -80px;
      background: none;
      box-shadow: none;
      padding: 0;

      h3 {

        color: $white;
      }

      p {

        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba($black, 0.5);
        background: $white;
      }
    }
  }

  @include media-breakpoint-down(md) {

    margin: 0;
  }
}

.slider-holder-overflow {

  position: relative;
  overflow: hidden;

  .swiper-holder-2 {

    .swiper-slide {

      opacity: 0.5;
      height: auto;
      
      .card-trip {
        
        a {

          pointer-events: none !important;

          &::after {

            display: none;
          }
        }
      }

      &.swiper-slide-active {

        opacity: 1;

        .card-trip {

          @include transform(translateY(30px));

          a {

            pointer-events: initial;

            &::after {

              display: block;
            }
          }
        }
      }
    }

    .swiper-button-next {

      right: 30px;
      top: calc(50% - 3.5rem);
    }

    .swiper-button-prev {

      left: 30px; 
      top: calc(50% - 4rem);
    }
  }

  @include media-breakpoint-down(md){

    .swiper-holder-2,
    .swiper-holder-map,
    .swiper-google-map {
      
      .swiper-slide {

        height: auto !important;
      }
    }
  }
}

.map-slider {

  display: flex;
  position: relative;
  border-radius: 20px;
  overflow: hidden;

  @include media-breakpoint-down(sm) {

    flex-direction: column;
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
  
  .destination-slide {

    flex-grow: 1;
    flex-basis: 450px;
    max-width: 450px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    .swiper-holder-2,
    .swiper-holder-map,
    .swiper-google-map {

      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }

    @include media-breakpoint-down(md) {

      flex-basis: 400px;
      max-width: 400px;
    }

    @include media-breakpoint-down(sm) {

      flex-basis: 100%;
      max-width: 100%;
      border-radius: 0;

      .swiper-holder-2,
      .swiper-holder-map,
      .swiper-google-map {

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .destination-map {

    position: relative;
    overflow: hidden;
    flex-grow: 1;
    width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: $gray-250;
    
    #google-map{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;


        @include media-breakpoint-down(sm) {
         
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
    }
    
    .acf-map {

      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      > div , > div > .gm-style {

        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        overflow:hidden;
      }
    }

    .fa-3x {

      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    @include media-breakpoint-down(sm) {      
  
      order: -1;
      border-radius: 0;
      width: 100%;
      height: 300px;

      .acf-map {

        border-radius: 0;
  
        > div , > div > .gm-style {
  
          border-radius: 0;
        }
      }
    }
  }

  .swiper-holder-2,
  .swiper-holder-map,
  .swiper-google-map {

    .swiper-button-next,
    .swiper-button-prev {

      top: 292px;

      &::after {

        font-size: rem(30px);
        color: $white;
      }

      @include media-breakpoint-down(sm) {

        top: 192px;

        &::after {

          font-size: rem(20px);
        }
      }
    }
  }

}

.card-map {

  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  @include media-breakpoint-down(sm) {

    border-radius: 0;
  }

  .card-img {

    height: 310px;
    position: relative;
    

    img {

      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      background: $gray-250;
      border-top-left-radius: 20px;
    }

    &::after {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      @include linear-gradient(to top, $black 0, transparent 30%);
    }

    @include media-breakpoint-down(sm) {

      height: 210px;

      img {

        border-radius: 0;
      }
    }
  }

  .card-body {

    display: flex;
    flex-direction: column;
    position: relative;
    background: $black;
    color: $white;
    text-align: center;
    z-index: 2;
    padding-bottom: 40px;
    border-bottom-left-radius: 20px;

    h5 {

      font-size: rem(20px);
      font-weight: 700;
      margin-bottom: 20px;
      margin-top: -50px;
      padding: 0 20px;
    }

    .btn {

      font-size: rem(14px);

      &:hover {
        background: $white;
      }
    }

    @include media-breakpoint-down(sm) {

      border-radius: 0;
    }

    @include media-breakpoint-down(xs) {

      font-size: rem(12px);

      h5 {

        font-size: rem(16px);
      }
    }
  }
}

.non-swiper-slide {

  display: none;
}

.gm-style {

  .gm-style-iw-c {

    max-width: 300px !important;
  
    .gm-style-iw-chr {
  
      position: absolute;
      top: 0;
      right: 0;
  
      button {
  
        width: 34px !important;
        height: 34px !important;
  
        span {
  
          width: 20px !important;
          height: 20px !important;
          margin: 8px !important;
        }
      }
    }
  }

  .gm-style-iw-d {

    font-size: rem(12px);
    padding-top: 12px;

    h5 {

      font-size: rem(14px);
      padding-right: 20px;
      margin-bottom: 5px;
    }

    p {

      margin-bottom: 12px;
    }

    a {

      text-decoration: none !important;
      outline: none !important;
    }
  }
}
