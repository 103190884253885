// ==========================================================================
// Global Styles
// ==========================================================================

body {

  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .site-content {

    flex-grow: 1;
    position: relative;
  }
}

.pointer-disable {
  pointer-events: none;
}

.container {
  @include break-from( xlarge ) {
    max-width: 1350px;
  }
}

h1 {
  // font-size: $h1-font-size * .6;
  font-size: rem(40px);
  font-weight: 700;

  @include break-from( medium ) {
    // font-size: $h1-font-size;
    font-size: rem(60px);
  }
}

h2 {
  // font-size: $h2-font-size * .6;
  font-size: rem(40px);
  font-weight: 700;

  @include break-from( medium ) {
    // font-size: $h2-font-size;
    font-size: rem(55px);

    &.heading-title {
      font-size: $h2-font-size * .6;
    }
  }

  &.golden-ticket-mssg {
    font-size: $font-size-base * 1.125;
  }
}

h4 {
  font-weight: 700;
}

h3 {
  font-family: 'moontime';
  font-size: $h3-font-size * .8;

  @include break-from( medium ) {
    font-size: $h3-font-size * 1.6;
  }
}

.montserrat-light {
  font-weight: 300;
}

.montserrat-semibold {
  font-weight: 600;
}

.montserrat-extrabold {
  font-weight: 800;
}

.fs-20 {
  font-size: 20px;
  line-height: 24px;
}

.fs-50 {
  font-size: 50px;
  line-height: 24px;

  @include break-until ( small ) {
    font-size: 37px;
  }
}

.fst-italic {
  font-style: italic;
}

.weight-400 {
  font-weight: 400;
}

b {
  font-family: 'Montserrat';
  font-weight: 700;

  &.weight-500 {
    font-weight: 500;
  }

  &.weight-600 {
    font-weight: 600;
  }

  &.bold {
    font-weight: 700;
  }
}

.flex-shrink-0 {

  flex-shrink: 0;
}

.lead-text {

  font-size: rem(30px);
  font-weight: 700;
  font-style: italic;

  span {

    font-style: normal;
    font-weight: 300;
  }

  p {

    margin-bottom: 0;
  }

  @include media-breakpoint-down(sm) {

    font-size: rem(20px);
  }
}

.lead-22 {

  font-size: rem(22px);

  @include media-breakpoint-down(md) {

    font-size: rem(18px);
  }
}

.mt-10 {

  margin-top: 100px;

  @include media-breakpoint-down(sm) {

    margin-top: 50px;
  }
}

.applied {
  color: $green;
}

.no-events {
  pointer-events: none;
}

.form-control {
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 0 0 0 !important;
    outline: none;
  }

  select,
  input,
  button {
    &:hover,
    &:active,
    &:focus {
          outline: none;
          box-shadow: 0 0 0 0 !important;
      }
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}

.no-border {
  border: 0 !important;
}

.content-sm {

  max-width: 600px;
}

.gradient-bg {

  background: rgb(255,101,100);
  background: linear-gradient(342deg, rgba(255,101,100,1) 0%, rgba(255,204,100,1) 100%);
}

.sticky-nav {
  position: fixed;
  width: 100%;
  left: 0;
  background: $white;
  padding: 1em;
  margin: 0;
  z-index: 99;
  top: 3.5em;
}

.acf-map {
  width: 100%;
  height: 320px;
  margin: 0;

  @include break-from( medium) {
    height: 100%;
  }
}

.sticky-cart {
  position: fixed;

  @include break-from( medium) {
    position: sticky !important;
  }
  align-self: end;
}

.travel-advisory {
  z-index: 101;
  position: relative;
  width: 100%;
  text-align: center;
  background: #000;
  color: #fff;
  padding: 10px;
  left: 0;
  top: 0;

  p {
    margin: 0;
  }
  
  @include media-breakpoint-down(sm) {

    font-size: rem(12px);
  }

  @include media-breakpoint-down(xs) {

    font-size: rem(10px);
  }
}

.col-md-90 {
  width: 95%;

  @include break-until(1053px) {

    width: 100%;
  }
}

.zx-2 {

  z-index: 2;
}

// message error
p {
  &.no-room {
    font-size: $font-size-base * 1.5;
    margin-bottom: $spacer * .3;
    font-family: 'Montserrat';
    font-weight: 500;
    width: 100%;
  }

  &.change-date {
    font-size: $font-size-base;
    margin-bottom: $spacer * .3;
    font-family: 'Montserrat';
    font-weight: 400;
    width: 100%;
  }

  &.error-mssg {
    color: $red;
    display: none;
  }

  &.warning {

    input {
      border: 1px solid $red !important;
    }
  }
}

// buttons
.btn {
  
  &.btn-primary {
    background-color: $black;
    color: $white;
    padding: .5em 2.3em;
    border: 2px solid $black;
    text-transform: uppercase;
    border-radius: 30px;

      &:hover {
        background-color: transparent;
        border: 2px solid $black;
        color: $black;
      }

      &:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
  }

  &.btn-secondary {
    background-color: transparent;
    color: $black;
    padding: .5em 2.3em;
    border: 1px solid $gray;
    text-transform: uppercase;
    border-radius: 30px;

    &.white {
      color: $white;
    }

    &:hover {
      background-color: $gray;
      border: 1px solid $gray;
      text-decoration: none;
      color: $black;
    }
  }

  &.btn-third {
    background-color: $yellow;
    color: $black;
    padding: .5em 2.3em;
    border: 1px solid $yellow;
    text-transform: uppercase;
    border-radius: 30px;

    &.bold {

      font-weight: 700 !important;
    }

    &.shadow {

      box-shadow: 0 0 9px 2px rgba($black, 0.2) !important;
    }

    &.sm-corner {
      border-radius: 10px;
      text-transform: none;
    }

    &.large {
      font-size: rem(20px);

      @include media-breakpoint-down(sm) {

        font-size: rem(16px);
      }
    }

    &.no-tranform {

      text-transform: none;
      text-shadow: none;
    }

    &:hover {
      background-color: $white;
      border: 1px solid $yellow;
      text-decoration: none;
      color: $black;
    }
  }

  &.btn-square {
    background-color: $yellow;
    color: $black;
    border-radius: 0 ;
    padding: .5em 2.3em;
    border: 1px solid $yellow;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 700;

    &:hover {
      background-color: $white;
      border: 1px solid $yellow;
      text-decoration: none;
      color: $black;
    }
  }

  &.btn-lgreen {

    font-weight: 700;
    padding: .5em 2.3em;
    text-transform: uppercase;
    border-radius: 30px;
    background: $green-55;
    box-shadow: 0 0 5px 1px rgba($black, 0.2);

    &:hover {

      background: darken($green-55, 10%);
    }

  }

  &.btn-lg {

    @include media-breakpoint-down(sm) {

      font-size: rem(14px);
    }
  }

  &.btn-red {
    background-color: $red-400;
    color: $black;
    padding: .5em 2.3em;
    border: 1px solid $red-400;
    text-transform: uppercase;
    border-radius: 30px;

    &:hover {

      background-color: darken($red-400, 5%) !important;
    }
  }

  &.btn-green {

    font-weight: 700;
    padding: .5em 2.3em;
    text-transform: uppercase;
    border-radius: 30px;
    background: $green-45;
    color: $white;

    &:hover {

      background: darken($green-55, 10%);
    }

  }

  &.btn-outline {

    border: 1px solid $default-color;
    color: $default-color !important;
    background: transparent !important;

    &.white {
      border-color: $white;
      color: $white !important;
      background-color: rgba($white, 0.2) !important;

      &:hover {
        background-color: $white !important;
        color: $default-color !important;
      }
    }

    &.large {

      font-size: rem(20px);
      padding: 10px 20px;

      @include media-breakpoint-down(sm) {
        font-size: rem(18px);
      }
    }

    &:hover {

      color: $white !important;
      background: $default-color !important;
    }
  }

  &.btn-ghost {

    font-size: rem(14px) !important;
    font-weight: 500;
    padding: 5px !important;
    font-style: italic;
    border: none;
    background: none;
    outline: none !important;
    box-shadow: none !important;
  }

  &.btn-white {

    font-size: rem(16px);
    font-weight: 700;
    padding: 7px 15px !important;
    border: none;
    background: $white;
    outline: none !important;
    box-shadow: 0 0 9px 2px rgba($black, 0.2) !important;
  }

  &.btn-outline-white {

    font-size: rem(17px);
    font-weight: 700;
    border: 1px solid $white;
    color: $white !important;
    background: transparent !important;
    border-radius: 30px;
    padding: 8px 20px;

    &:hover {

      background: rgba($black, 0.1) !important;
    }
  }

  &.btn-link {

    font-size: rem(16px);
    font-weight: 600;
    border: none;
    background: none;
    outline: none !important;
    box-shadow: none !important;
    text-decoration: underline;
    color: $yellow;
    padding-left: 0;
    padding-right: 0;
  }

  &.btn-blue {
    background-color: $blue-500;
    color: $white;
    padding: .5em 2.3em;
    border: 1px solid $blue-500;
    border-radius: 30px;

    &.bold {

      font-weight: 700 !important;
    }

    &.shadow {

      box-shadow: 0 0 9px 2px rgba($black, 0.2) !important;
    }

    &.sm-corner {
      border-radius: 10px;
      text-transform: none;
    }

    &.large {
      font-size: rem(20px);

      @include media-breakpoint-down(sm) {

        font-size: rem(16px);
      }
    }

    &:hover {
      background-color: darken($blue-500, 5%);
      border: 1px solid darken($blue-500, 5%);
      text-decoration: none;
      color: $white;
    }
  }

  &.btn-gradient {

    background: rgb(158,127,181);
    background: linear-gradient(90deg, rgba(158,127,181,1) 0%, rgba(83,126,155,1) 30%, rgba(158,127,181,1) 80%);
    background-size: 130% 100%;
    color: $white;
    padding: .5em 2.3em;
    border-radius: 10px;
    background-position: 0% 50%;
    @include transition(background-position 0.5s linear);

    &.large {

      font-size: rem(20px);

      @include media-breakpoint-down(sm) {

        font-size: rem(16px);
      }
    }

    &.bold {
      font-weight: 700;
    }

    &:hover {
      background-position: -150% 100%;
    }
  }

  &.btn-purple {

    background-color: $purple-300;
    color: $white;
    padding: .5em 2.3em;
    border: 1px solid $purple-300;
    border-radius: 10px;
    font-weight: bold;

    &.large {

      font-size: rem(20px);

      @include media-breakpoint-down(sm) {

        font-size: rem(16px);
      }
    }

    &:hover {
      background-color: darken($purple-300, 5%);
      border: 1px solid darken($purple-300, 5%);
    }
  }
}

textarea {
  font-family: 'Montserrat';
  font-weight: 400;

  &:focus {
    outline: 0;
  }
}

input,
select {
  padding: 0;
  border: 0;
  outline: 0;
  font-family: 'Montserrat', FontAwesome;
  font-weight: 400;


  &:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
  }
}

// ajax spin
#spinner-ajax {
  text-align: center;
  display: none;
  padding: $spacer * 2;
}

  // input counter
  .count-wrapper {
    position: relative;
    margin-left: 5px;

    @include break-until ( small ) {
      margin-top: -10px;
    }

    @include media-breakpoint-down(lg) {

      justify-content: flex-end;
    }

    &.skeleton {
      a,
      span,
      input,
      button,
      .cancellation {
        color: transparent !important;
        background: transparent !important;
      }

      select,
      .remove-item,
      svg {
        visibility: hidden;
      }
    }

    @include flex-container( $align: center );
    flex-wrap: nowrap;

    @include break-from( medium ) {
      width: 350px;
    }

    .promo-title {
      position: absolute;
      top: 0;
      text-transform: uppercase;
      background: $green-35;
      font-size: $font-size-base * 0.75;
      padding: $spacer * .3;
      font-weight: 500;
      border-radius: 5px;
      left: 0;
      width: 145px;
      text-align: center;

      @include break-from( medium ) {
        top: .6em;
        left: -11em;
        min-width: 180px;
      }
    }

    .promo-off {
      position: absolute;
      background: $green-25;
      font-size: $font-size-base * 0.75;
      padding: $spacer * .3;
      color: $white;
      font-weight: 500;
      border-radius: 5px;
      left: 0;
      top: 3em;
      width: 145px;
      text-align: center;

      @include break-from( medium ) {
        left: -11em;
        min-width: 180px;
        top: 3.8em;
      }
    }

    .counter {
      border-radius: 0;
      text-align: center;
      background: $yellow;
      margin: 0;
      padding: 0;
      width: 45px;
      color: $white;
      padding: 0.5em 0;
      pointer-events: none;

      @include break-from( medium ) {
        width: 35px;
      }
    }

    .up_count {
      margin-bottom: 0;
      margin-left:0;
      border: 0px;
      background: rgba(247,212,74, 0.5);
      border-radius: 0;
      padding: .5em .5em;

      @include break-from( medium ) {
        padding: .5em .8em;
      }

      svg {
        font-size: $font-size-base * 0.875;

        @include break-from( medium ) {
          font-size: $font-size-base;
        }
      }
    }

    .down_count {
      margin-bottom: 0;
      margin-right:0;
      border: 0px;
      background: rgba(247,212,74, 0.5);
      border-radius: 0;
      padding: .5em .5em;

      @include break-from( medium ) {
        padding: .5em .8em;
      }

      svg {
        font-size: $font-size-base * 0.875;

        @include break-from( medium ) {
          font-size: $font-size-base;
        }
      }
    }

    select {
      cursor: pointer;
      font-weight: 700;
      padding: ($spacer * .3) 5px;
      border-radius: 5px;
      background: $light-gray;
      border: 1px solid $default-color;
      color: $default-color;
    }
  }

  .cancellation-container {

    position: relative;
    display: inline-block;

    #cancellation-desc,
    #refundable-desc,
    .fee-text-desc {

      top: 20px;
      left: 0;
      right: auto;
      min-width: 250px;
    }
  }

  #inclusive-info-desc {
    top: 1.25rem !important;

    ul {
      padding-left: 1em;
      padding-top: 1em;
    }
  }

  #inclusive-info-desc,
  #cancellation-desc,
  #refundable-desc
  .fee-text-desc {
    font-size: $font-size-base * 0.75;
    background: $white;
    top: $spacer * 2.5;
    z-index: 10;
    right: 0;
    width: 100%;
    padding: 1em;
    border: 1px solid $light-gray;
    border-radius: 5px;
    position: absolute;
    transition: visibility 0s, opacity 0.5s linear;
    text-align: left;
    font-weight: 400;

    strong {
      display: block;
      margin-bottom: $spacer * .5;
    }

    p {
      margin-bottom: 0;
    }

    @include break-from( medium ) {
      width: 312px;
    }
  }

  #refundable-desc {
    top: 5rem;
  }

  // currency
  .currency-modal {
    .modal-body {
        ul {
           @include flex-container( $align: stretch );
          justify-content: left;
          flex: 0 0 100%;
          padding: 0;

            li {
                flex: 0 0 25%;
                padding-bottom: $spacer;
                list-style: none;
                text-align: center;

                a {
                  color: $black;
                }
            }
        }
    }
  }

  // login modal
  .login-modal {
    .modal-header {

      border: none;
      @include break-until ( small ) {
        display: block !important;
        background: none !important;

        .closeReg {
          display: none;
        }
      }
    }
    form {
      &.lost_reset_password {
        button {
          font-weight: 700;
          font-size: 17px;
          line-height: 28px;
        }
      }
    }
  }
  .login-modal,
  .notice-modal {
    .modal-content {
      border-radius: 2.5rem;
      border: none;
      overflow: hidden;
      @include flex-container( $align: stretch );
      @include break-from ( medium ) {
        flex-direction: row-reverse;
      }

      .modal-header {
        background: url('../images/login-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        flex: 0 0 100%;
        display: none;

        .close {
          font-size: $font-size-base * 2.5;
        }

        .closeReg {
          float: left;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #000000;
          text-shadow: 0 1px 0 #fff;
          opacity: .5;
          position: absolute;
          left: 5px;
          border: none;
          background-color: transparent;
          z-index: 99999;
        }

        @include break-from( medium ) {
          flex: 0 0 35%;
          display: block;
        }
      }

      .modal-body {
        text-align: center;
        padding: $spacer * 2;
        flex: 0 0 100%;

        .close {
          top: 5px;
          right: 15px;
          position: absolute;

          span {
            font-size: $font-size-base * 2.5;
          }
          @include break-from( medium ) {
            display: none;
          }
        }

        @include break-from( medium ) {
          flex: 0 0 65%;
        }

        span {
          font-size: 0.8rem;

          &.warning {
            display: none;
            text-align: center;
            border: 1px $red solid;
            width: 100%;
            margin: 8px 0 0;
            padding: 2px 10px 0 10px;
            color: $red;
            transition: all 1000ms ease;

            &.active {
              display: block;
            }
          }
        }

        img {
          &.logo {
            width: 144px;
          }
        }

        h2 {
          font-size: $font-size-base * 1.5;
          font-family: 'Montserrat';
          font-weight: 600;
          text-align: center;
          margin: $spacer * .8;
          flex: 1;
        }

        p {
          font-size: $font-size-base * .8;
          width: 100%;
          float: none;

          &.sign-up {
            padding: 1.5em 0 0;
            margin: 0;

            a {
              cursor: pointer;
            }
          }
        }

        form {

          a {
            &.create-account {
              cursor: pointer;
            }
          }

          p {
            margin-bottom: 0;
            position: relative;

            label {
              display: none;
            }

            input[type="text"],
            input[type="password"],
            input[type="email"],
            input[type="date"],
            select {
              padding: .6em .6em;
              border: 1px solid $gray;
              border-radius: 8px;
              width: 100%;
              margin-bottom: 1em;
            }

            input[type="password"] {
              @include break-from ( medium ) {
                margin-bottom: 0;
              }
            }

            &.sign-up {
              padding: 0;

              @include break-until ( small ) {
                &.text-right {
                  margin-top: -10px;
                  margin-bottom: 10px;
                }
              }
            }

            &.login-submit {
              margin-bottom: $spacer;

              .button {
                &.button-primary {
                  background-color: $black;
                  color: $white;
                  border-radius: 0;
                  padding: .5em 2.3em;
                  border: 2px solid $black;
                  text-transform: uppercase;
                  border-radius: 30px;
                  font-weight: 600;

                    &:hover {
                      background-color: transparent;
                      border: 2px solid $black;
                      color: $black;
                    }

                    &:focus {
                      border-color: inherit;
                      -webkit-box-shadow: none;
                      box-shadow: none;
                  }
                }
              }
            }
            &.iti {
              width: 100%;
              padding-top: .6em;
              padding-bottom: .6em;
              border: 1px solid #e3e3e3;
              border-radius: 8px;
            }
          }
          ul {
            &.password-strength {
                padding: 0;
                text-align: left;
                width: 100%;
                float: right;

                li {
                    display: block;
                    color: $gray-150;
                    font-size: $font-size-base * 0.575;
                    padding-bottom: .5em;

                    @include break-from( medium ) {
                        display: inline-block;
                        font-size: $font-size-base * 0.85;
                        padding-right: 5px;
                    }
                }
            }
          }
          .show-password-input {
            position: absolute;
            right: 0.7em;
            top: 0.7em;
            cursor: pointer;

            &::after {
              font-family: WooCommerce;
              speak: never;
              font-weight: 400;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              margin-left: 0.618em;
              content: "\e010";
              text-decoration: none;
            }
          }
          .woocommerce-form-register__submit {
            font-weight: 700;
            font-size: 17px;
            line-height: 28px;

            &:disabled {
              background-color: #BCBCBC;
              border-color: #BCBCBC;
            }
          }
        }
      }
    }
  }

  // search
  .booking-search {
    top: 0;
    position: absolute;
    text-align: center;
    z-index: 101;
    left: 0;
    right: 0;
    padding: ($spacer * 6.5) $spacer 0;
    display: none;

    @include break-from( medium ) {
      display: block;
      padding: ($spacer * 3) ($spacer * 2.5) 0;
    }

    &.advisory {
      padding: ($spacer * 6.5) ($spacer * 2.5) 0;

      @include break-from( medium ) {
        padding: ($spacer * 3.5) ($spacer * 2.5) 0;
      }
    }

    &.search {
      .booking-search {
          padding: $spacer 0 0;
          position: relative;
      }
    }

    .container {
        position: relative;

        &.active {

          background: $light-gray;
          border-color: $light-gray;
        }

        @include break-from( medium ) {
          max-width: 890px;
          padding: ($spacer * .5) ($spacer * 3) ($spacer * .5) 2rem;
        }

        @include break-from( medium ) {
            @include flex-container( $align: center );
            justify-content: space-around;
            border-radius: $spacer * 4;
            background: $white;
            border: 1px $gray solid;
            box-shadow: 0 0 5px 1px rgba($black, 0.1)
        }

        .item {
            @include flex-item();
            @include flex-container( $align: center );
            align-items: stretch;
            justify-content: space-around;
            flex-direction: column;
            position: relative;
            cursor: pointer;

            * {
              position: relative;
              z-index: 2;
            }

            .remove-date {

              display: none;
              position: absolute;
              right: 25px;
              padding: 5px;
              top: 50%;
              width: 26px;
              @include transform(translateY(-50%));
              color: $gray-250;
              z-index: 2;
            }

            &.active {

              &:after {

                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% + 45px);
                height: calc(100% + 26px);
                content: '';
                background: $white;
                box-shadow: 0 0 10px 0 rgba($black, 0.3);
                z-index: 1;
                margin: -13px -33px;
                border-radius: 50px;
              }
            }

            &:nth-of-type(3) {

              &.active {
                &:after {

                  width: calc(100% + 105px);
                }
              }
            }

            @include break-until( medium ) {
              background: $white;
              margin-bottom: $spacer;
              border-radius: 30px;
              padding: $spacer * .3;

              p {
                color: $black !important;
              }

              .select2-container .select2-selection--single {
                border: 0;
              }
            }

            input {
              // font-family: 'montserrat-light';
              font-weight: 400;
              font-size:  $font-size-base * 0.875;
              border: 0;
              height: inherit;
              padding-top: 0;
              transition: 0;
              padding: $spacer * .1;
              margin: 0 auto;
              text-align: center;
              color: $black;
              line-height: 28px;
              cursor: pointer;
              background: none;
              // width: 190px;
              width: 100%;

              @include break-from( medium ) {
                border: 0;
                padding: 0 0.75rem;
                margin: inherit;
                text-align: left;
              }

              &:focus,
              &:hover {
                outline:none;
                -webkit-box-shadow: none;
                box-shadow: none;
              }

              &#check-in::placeholder, &#check-out::placeholder {
                color: $black;
              }
            }

            select {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              border: 0;
              height: inherit;
              font-size:  $font-size-base * 0.875;
              font-family: 'Montserrat';
              font-weight: 400;
              padding-top: 0;
              transition: 0;
              background: transparent;

              &:focus {
                border-color: inherit;
                -webkit-box-shadow: none;
                box-shadow: none;
                outline:none;
              }
            }

            p {
              margin: 0;
              text-align: center;
              color: $gray;
              width: 180px;

              @include break-from( medium ) {
                color: $white;
                position: relative;
                font-family: 'Montserrat';
                font-weight: 500;
                text-align: left;
                font-size:  $font-size-base * 0.875;
                padding: ($spacer * .3) 0 0;
                margin: 0;
                top: 0;
                color: $black;

                &.go-place {
                  padding-left: 15px;
                }

                &.date {
                  padding-left: 13px;
                  width: 100%;
                }
              }
            }

            button {
              &.mobile {
                position: absolute;
                border-radius: 4em;
                padding: 1.2em .1em;
                border: 0;
                cursor: pointer;
                background-image: url(../images/Search.png);
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
                color: transparent;
                top: 5em;
                right: 0;
                left: 0;
                margin: 0 auto;
                background-color: transparent;
                height: 60px;
                width: 60px;

                &:hover {
                  border: 0;
                  background-color: none;
                }

                @include break-from( medium ) {
                  display: none;
                }
              }

              &.desktop {
                display: none;

                @include break-from( medium ) {
                  display: block;
                }

                position: absolute;
                // right: -3.8em;
                right: -63px;
                border-radius: 4em;
                padding: 1.2em .1em;
                border: 0;
                cursor: pointer;
                background-image: url(../images/Search.png);
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
                color: transparent;
                top: -5em;
                border: 0;
                background-color: $white;
                @include transition(all 0.3s ease-in-out);

                &:hover {
                  border: 0;
                  background-color: $white;
                  filter: brightness(1.1);
                }

                @include break-from( medium ) {
                    top: inherit;
                }
              }
            }

            &:nth-of-type(1) {
                padding-right: 0;
                margin-right: 0;

                @include break-from( medium ) {
                    border-right: 1px solid $gray;
                    padding-right: $spacer;
                    margin-right: $spacer;
                }
            }

          &:nth-of-type(2) {
                padding-right: 0;
                margin-right: 0;

                @include break-from( medium ) {
                    border-right: 1px solid $gray;
                    padding-right: $spacer;
                    margin-right: $spacer;
                }
          }
      }
    }
}

// select2
.currency-exchange {
  text-align: center;
  font-size: rem(13px);

  .select2-container--default {

    cursor: pointer;

    .select2-selection--single .select2-selection__rendered {
      padding: 0;

      .select2-selection__placeholder {
        font-size: 10px;
        float: right;
        padding-right: 0;
        padding-left: 32px;
        color: $default-color;
        width: 77px;
        white-space: initial;
        line-height: 1.1;
        padding-top: 2px;
        text-align: center;

        @include break-until ( medium ) {
          padding-left: 30px;
        }

        @include media-breakpoint-down(xs) {
          padding-right: 0;
          color: transparent;
          width: 40px;
        }

        &:after {
          content: '';
          position: absolute;
          left: 1px;
          top: 3px;
          width: 22px;
          height: 22px;
          background: url('../images/icons8-currency-exchange-100.png') no-repeat center;
          background-size: 100%;
        }

        @include media-breakpoint-down(xs) {

          &:after {

            left: 26px;
            width: 26px;
            height: 26px;
          }
        }
      }
    }

    .select2-selection__rendered {

      @include media-breakpoint-down(xs) {
  
        .img-flag {

          @include transform(translateX(15px));
  
          + span {
  
            display: none;
          }
        }
      }
    }

  }
}

.currency-exchange {

  .select2-container--default .select2-results>.select2-results__options {
    
    max-height: 100% !important;
  }
}

.booking-search {
  .select2-container--default {
    .select2-selection--single {
      border: 0;
    }

    .select2-results {
      >.select2-results__options {
        max-height: 300px;
      }
    }

    .select2-results__option--disabled {
      padding-top: 15px !important;
      padding-bottom: 20px !important;
      border-bottom: 0.5px solid #BCBCBC;
      margin-bottom: 23px;

      span {
        &.mad {
          border-bottom: 1px solid #000000;
          padding-bottom: 22px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
        }
      }
    }
  }

  .select2-container {
    display: inherit;
    width: 100% !important;

    @include break-from( medium) {
      padding: 0 0.75rem 0 0.45rem;
    }

    span {
      outline: none !important;
      width: 100%;
    }

    .dropdown-wrapper {
      display: none;

      @include break-from( medium) {
        display: inline;
      }
    }

    .select2-selection--single {
      border-radius: 0;
      border: 1px solid $gray;
      background: none;

      @include break-from( medium ) {
        border: 0;
      }

      @include break-from( medium) {
        border-radius: inherit;
      }

      .select2-selection__rendered {
        // font-family: 'montserrat-light';
        font-size: 0.875rem;
        font-weight: 400;
        padding-left: 0;
        padding-right: 0;
        line-height: 28px;
        color: $black;

        @include break-from( medium) {
          padding-left: 8px;
          max-width: 225px;
        }
      }
    }

    &.select2-container--default {

      @include break-from( medium ) {

        .select2-dropdown--below {

          top: 28px;
        }

        .select2-dropdown--above {

          top: -55px;
        }
      }
      &.select2-container--open:not(.select2-container--below) {

        .select2-dropdown--below,
        .select2-dropdown--above {
          ul {
            li {
              font-size: 13px;
              // font-family: 'montserrat-medium';
              font-weight: 500;

              span {
                &.country {
                  color: #6C6C6C;
                  font-size: 13px;
                }
              }
            }
          }
        }

        @include break-from( medium ) {

          .select2-dropdown--below,
          .select2-dropdown--above {
            width: 292px !important;
            border: 0;
            border-radius: 40px;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;
            overflow: hidden;
            box-shadow: 0px 0px 14px 0px rgba($black, 0.30);
            left: -33px;

            ul {
              li {
                padding: .5em 22px;
                text-align: left;
                // font-family: 'montserrat-medium';
                font-weight: 500;
                font-size: 13px;
                color: $black;
                &.select2-results__option--highlighted{
                  background: $black;
                    color: $white;
                  &.select2-results__option--selectable {
                    background: $black;
                    color: $white;

                    span {
                      &.country {
                        color: $white;
                        font-family: 'Montserrat';
                        font-size: 13px;
                      }
                    }
                  }
                }
                &.select2-results__option[aria-disabled=true] {
                  padding-top: 26px !important;
                  padding-bottom: 26px !important;
                  border-bottom: solid .5px #BCBCBC;
                  margin-bottom: 22px;

                  span {
                    &.mad {
                      border-bottom: 1px solid #121212;
                      padding-bottom: 27px;
                      font-family: 'Montserrat';
                      font-style: normal;
                      font-weight: 500;
                    }
                  }
                }

                &:last-child {

                  &:hover {

                    box-shadow: 2px 2px rgba($black, 0.1);
                  }
                }
              }
            }
          }
        }
      }

      .select2-selection__arrow {
        display: none;
      }
    }
  }
}

.currency-exchange {
  
  margin-bottom: 34px;

  &.is-dark {

    .currency {

      + {
  
        .select2-container--default {
  
          @include media-breakpoint-up(sm) {
  
            border: 1px solid $white !important;
          }

          &:after {

            background: url('../images/arrow-down-white.png') no-repeat center;
          }
        }
      }
    }

    .select2-container--default {
      
      background: $black;

      .select2-selection__rendered {

        color: $white;

        .select2-selection__placeholder {

          color: $white !important;

          &:after {
            content: '';
            position: absolute;
            left: 1px;
            top: 3px;
            width: 22px;
            height: 22px;
            background: url('../images/icons8-currency-exchange-100-white.png') no-repeat center;
            background-size: 100%;
          }
        }
      }
    }


    .select2-dropdown {

      background: $black;
    }

    .select2-container.select2-container--default .select2-results__option--highlighted[aria-selected],
    .select2-container--default .select2-results__option[aria-selected=true] {

      background: $white;
      color: $black;
    }

  }

  .currency {

    + {

      .select2-container--default {

        @include media-breakpoint-up(sm) {

          border: 1px solid $default-color !important;
        }

        &:after {

          position: absolute;
          top: 50%;
          right: 10px;
          content: '';
          width: 11px;
          height: 11px;
          background: url('../images/arrow-down.png') no-repeat center;
          @include transform(translateY(-50%));
          z-index: 0;
        }
      }
    }
  }

  .select2-dropdown {

    &.select2-dropdown--above {
  
      border-radius: 15px;
      overflow: hidden;
      top: -8px;
      border-bottom: 1px solid $gray-250;

      li {

        font-size: 0.813rem;
      }

      @include break-from(medium) {

        li {

          padding: 0.5em 10px;
        }
      }

      @include media-breakpoint-down(sm) {

        li {

          font-size: rem(10px);
        }
      }
    }
  }


  .select2-container--default {

    width: auto !important;
    border-radius: 23px;
    padding-right: 0 !important;
    padding-left: 0.75rem;
    background: $white;

    .selection {

      position: relative;
      z-index: 1;
    }

    .select2-selection--single {

      padding: .3em 0 0;
      height: 36px;
      border-radius: 23px !important;
      padding-right: 25px;
      background: none !important;
      border: none;

      @include media-breakpoint-down(xs) {

        padding-right: 15px;
      }
    }

    .select2-selection__rendered {

      overflow: visible !important;
      text-align: left;
    }

    .select2-selection__placeholder {

      position: relative;
    }

    @include media-breakpoint-down(xs) {

      padding-right: 0 !important;
      border: none !important;

      &::after {

        display: none;
      }

      .select2-selection__placeholder {

        padding-left: 0 !important;
      }
    }
  }

  .select2-container {
    display: inherit;

    @include break-from( medium ) {
      padding: 0 0.75rem 0 0.45rem;
    }

    @include break-until ( small ) {
      width: auto !important;
      min-width: 60px;
    }

    .dropdown-wrapper {
      display: none;

      @include break-from( medium) {
        display: inline;
      }
    }

    &.select2-container--default {
      &.select2-container--open:not(.select2-container--below) {

        .select2-dropdown--below {

          margin-top: 8px;
          border-radius: 15px !important;
          border: 0;
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 0;
          overflow: hidden;
          border: 1px solid $gray-250;

          ul {
            li {
              font-size: $font-size-base * 0.813;
            }
          }
        }

        @include media-breakpoint-down(sm){

          .select2-dropdown--below {
  
            ul {
              li {
                font-size: rem(10px);
              }
            }
          }

        }

        @include break-from( medium ) {

          .select2-dropdown--below {      

            ul {
              li {
                padding: .5em 10px;
                font-size: $font-size-base * 0.813;

                &.select2-results__option--highlighted{
                  &.select2-results__option--selectable {
                    background: $black;
                  }
                }
              }
            }
          }
        }
      }

      .select2-selection__arrow {

        top: 6px;
        right: 20px;
        opacity: 0;
      }

      .select2-results__option--highlighted[aria-selected] {
        background-color: #000;
      }
    }
  }
}

// heading
.sub-title {
  font-size: $font-size-base * 1.875;
}

.sorry-no-result {
  font-size: $font-size-base * 1.375;
}

// freshchat
#custom_fc_button {
  width: auto;
  position: fixed;
  right: 20px;
  bottom: 50px;
  z-index: 993;

  @include break-until ( small ) {
    right: 0 !important;
    left: auto !important;
    bottom: 95px;
  }

  #imageicon {
    width: 55px;
    max-width: inherit;

    @include break-from( medium ) {
      height: 50px;
      width: 96px;
    }
    
  }

  .web-cfchat {
    display: none;

    @include break-from ( 560px ) {
      display: block;
    }
  }

  .mobile-cfchat {
    display: none;

    @include break-until ( small ) {
      display: block;
      width: 30px !important;
      height: auto !important;
    }
  }
}

.layout-backdrop {

  &[aria-hidden="true"] {

    display: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.5); 
  z-index: 993;

  &.override {

    z-index: 1022;
  }

  @include break-from(1300px) {

    display: none;

    &.override {

      display: initial;
    }
  }

  @include media-breakpoint-down(sm) {

    &.override {

      display: none;
    }
  }
}

.overlap-hidden {
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.collapse {

  display: block !important;
  
  &:not(.show) {
    display: none !important;
  }
}

.list-count {

  padding: 0;
  list-style: none;

  li {

    display: flex;
    margin-bottom: 20px;

    &:last-child {

      margin-bottom: 0;
    }
    
    .count {

      font-size: rem(13px);
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      color: $white;
      background: $default-color;
      margin-right: 20px;
    }
  }
}

.stars {
  display: inline-block;
  font-size: 0;
}
.stars::before {
  content: "★★★★★";
  font-size: 24px;
  letter-spacing: 3px;
  background: linear-gradient(90deg, gold calc(var(--rating) * 20%), lightgray calc(var(--rating) * 20%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
