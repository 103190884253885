.swiper-holder-2,
.swiper-holder-map,
.swiper-holder-3,
.swiper-holder-thumb,
.swiper-google-map {

  position: relative;

}

.swiper-holder {

  position: relative;
  max-width: 800px;
  margin: 0 auto;

  .swiper-slide {

    height: auto;
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    box-shadow: 0 4px 5px rgba($black,0.3);
    cursor: pointer;

    img {

      filter: blur(6px);
      @include transition(all 0.3s ease);
      border-radius: inherit;
      opacity: 0.7;
    }

    video,
    .video-player {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: blur(6px);
      @include transition(all 0.3s ease);
      border-radius: inherit;
      opacity: 0.7;
    }

    &.swiper-slide-active {

      cursor: default;

      img,
      video,
      .video-player {

        opacity: 1;
        filter: blur(0);
      }
    }
  }

  @include media-breakpoint-down(xs) {

    margin-left: -15px;
    margin-right: -15px;

    .swiper-slide {

      border-radius: 20px;
    }
  }
}

.swiper-pagination {

  .swiper-pagination-bullet {

    width: 10px;
    height: 10px;
    box-shadow: 0 0 5px 1px rgba($black,0.9);

    &.swiper-pagination-bullet-active {

      background: $white;
    }
  }

  &.outside {

    bottom: 5px;
  }

  &.white {

    .swiper-pagination-bullet {

      box-shadow: 0 0 5px 1px rgba($black,.9) !important;
      background: $gray-250 !important;
      margin: 4px !important;
  
      &.swiper-pagination-bullet-active {
  
        background: $white !important;
      }
    }
  }
}

.swiper-fade {

  .swiper-slide {

    opacity: 0 !important;

    &.swiper-slide-active {

      opacity: 1 !important;
    }
  }

}

.video-player::-webkit-media-controls {
  display: none !important;
	opacity: 0 !important;
}

/* Could Use thise as well for Individual Controls */
.video-player::-webkit-media-controls-play-button {
	display: none !important;
	opacity: 0 !important;
}

.video-player::-webkit-media-controls-volume-slider {
	display: none !important;
	opacity: 0 !important;
}

.video-player::-webkit-media-controls-mute-button {
	display: none !important;
	opacity: 0 !important;
}

.video-player::-webkit-media-controls-timeline {
	display: none !important;
	opacity: 0 !important;
}

.video-player::-webkit-media-controls-current-time-display {
	display: none !important;
	opacity: 0 !important;
}

.video-player::-webkit-media-controls-start-playback-button {
  display: none !important;
	-webkit-appearance: none;
	opacity: 0;
}

.video-player::-webkit-media-controls-panel,
.video-player::-webkit-media-controls-start-playback-button {
  display: none !important;
}

.video-player::slotted::-webkit-media-controls-container{
	display:none !important;
	visibility: hidden!important;
	opacity: 0 !important;
 -webkit-appearance: none !important;
}

.swiper-button-icon {

  opacity: 0.6;
  @include transition(all 0.3s ease);

  img {

    width: 50px;
    max-width: 50px;
  }

  &::after {

    display: none;
  }

  &:hover {

    opacity: 1;
  }
}

.swiper-button-hidden {

  display: none !important;
}
