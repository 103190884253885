// ==========================================================================
// Footer Styles
// ==========================================================================

footer {
    position: relative;
    background: $black;
    padding-top: rem(60px);
    padding-bottom: rem(20px);

    @include break-from( medium) {
        padding-left: $spacer * 1.5;
        padding-right: $spacer * 1.5;
    }

    &#booking {
        @include break-until( medium) {
            display: none;
        }
    }

    .container {
        @include flex-container( $align: flex-start );
        flex: 0 0 100%;

        @include media-breakpoint-down(xs) {

            flex-direction: column;
        }

        section {
            @include flex-item();
            color: $white;
            flex: 0 0 20%;
            width: 20%;


            @include media-breakpoint-down(lg) {

                flex: 0 0 33.333333%;
                width: 33.333333%;
            }

            @include media-breakpoint-down(md) {

                flex: 0 0 50%;
                width: 50%;
            }

            @include media-breakpoint-down(sm) {

                // flex: 0 0 100%;
                width: 100%;
            }

            &:last-of-type {
                // flex: 0 0 100%;

                @include break-from( medium ) {
                    flex: 0 0 20%;
                }
            }

            h4 {
                color: $white;
                text-transform: uppercase;
                // font-family: 'montserrat-extrabold';
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                margin-bottom: 20px;
            }

            &.copy-right {
                a {
                    color: $white;
                }

                ul {
                    margin: 0;

                    li {
                        display: inline-block;
                        padding: 0 6px ($spacer*.5) 0;
                    }
                }

                p {
                    font-size: 12px;

                    @include break-until ( small ) {
                        margin-bottom: 40px;
                    }
                }
            }

            .logo {
                width: 144px;
            }

            ul {
                padding: 0;
                font-size: rem(12px);

                li {
                    list-style: none;
                    padding-bottom: rem(23px);

                    a{
                        color: $white;
                    }

                    @include media-breakpoint-down(sm) {

                        padding-bottom: 10px;
                    }
                }
            }

            &.widget_text {

                margin-bottom: 20px;
            }
        }

        button {
            &.needsclick {
                font-weight: 600 !important;
            }
        }
    }

    .container-fluid {
        @include flex-container( $align: flex-start );
        flex: 0 0 100%;

        section {
            @include flex-item();
            color: $white;
            flex: 0 0 100%;

            @include break-from( medium) {
                flex: 0 0 20%;
            }

            &:last-of-type {
                flex: 0 0 100%;

                @include break-from( medium) {
                    flex: 0 0 20%;
                }
            }

            h4 {
                color: $white;
                text-transform: uppercase;
                font-weight: 500;
            }

            &.copy-right {
                a {
                    color: $white;
                }

                ul {
                    margin: 0;

                    li {
                        display: inline-block;
                        padding: 0 ($spacer*.5) ($spacer*.5) 0;
                    }
                }
            }

            ul {
                padding: 0;

                li {
                    list-style: none;
                    padding-bottom: $spacer * .6;

                    a{
                        color: $white;
                    }
                }
            }
        }
    }

    .footer-flex {

        width: 60%;
        display: flex;
        flex-wrap: wrap;

        .flex-nav {

            width: 33.333333%;

            section {


                width: 100%;
                padding-right: 10px;
            }
        }

        @include media-breakpoint-down(lg) {

            width: 100%;
        }

        @include media-breakpoint-down(sm) {

            .flex-nav {

                width: 50%;

                &:last-child {

                    width: 100%;
                    display: flex;

                    section {

                        width: 50%;
                    }
                }
            }
        }

        @include media-breakpoint-down(xs) {
            
            .flex-nav {

                width: 100%;

                &:last-child {

                    flex-direction: column;

                    section {

                        width: 100%;
                    }
                }
            }
        }
    }
}
