// ==========================================================================
//  mobile Styles
// ==========================================================================

.mobile {
    &.booking-counntry-search {
        position: fixed;
        z-index: 992;
        bottom: 0;
        width: 100%;

        @include break-from(medium) {
            display: none;
        }

        &.active {

            z-index: 994;

            #mcalendar-booking-search {
                @include transform(translateY(0%));
            }
        }

        .close {

            display: flex;
            float: none;
            margin: 0 auto;
            margin-bottom: 10px;
            height: 5px;

            span {

                display: inline-block;
                width: 70px;
                height: 5px;
                background: $gray-250;
                border-radius: 10px;
            }
        }

        #mcalendar-booking-search {

            position: fixed;
            bottom: 0;
            width: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background: $white;
            @include transition(all 0.3s ease-in-out);
            box-shadow: 0px 1px 3px $black;
            padding: 20px;
            @include transform(translateY(105%));

            .form-control {

                height: 45px;
                font-size: rem(14px);

                &:hover,
                &:active,
                &:focus {
                    box-shadow: 0 0 0 0 !important;
                    outline: none;
                    border: 1px solid $border-color;
                }

                select,
                input,
                button {
                    border: 0px;
                    outline: 0px;

                    &:hover,
                    &:active,
                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 0 !important;
                        border: 0;
                        border: 1px solid $border-color;
                    }
                }
            }

            .go-places,
            .icalendar {
                padding-bottom: $spacer * .5;

                label {
                    font-weight: 500;
                }

                .form-control:disabled,
                .form-control[readonly] {
                    background-color: $white;
                }
            }

            .icalendar {
                position: relative;

                &:before {
                    content: " ";
                    background-image: url(../images/icons8-calendar-96.png);
                    width: 21px;
                    height: 21px;
                    position: absolute;
                    bottom: 20px;
                    right: 5px;
                }
            }

            [type="submit"] {
                padding: 15px;
                margin: 0;
                background: $yellow;
                color: $black;
                font-size: $font-size-base * 1.25;
                width: 100%;
                border: 0;
                text-transform: uppercase;
                font-weight: 700;
                border-radius: 5px;

                &:disabled {
                    background: #e3e3e3;
                }
            }
        }

        h2 {
            margin: 20px;
            text-align: center;

            a {
                padding: $spacer * .75;
                background: $yellow;
                color: $black;
                font-size: $font-size-base * 1.25;
                cursor: pointer;
                width: 100%;
                border: 0;
                display: inline-block;
                border-radius: 0;
                font-weight: 700;
                border-radius: 5px;
                box-shadow: 0 0 6px 1px rgba($black, 0.3);
            }
        }

        .book-now {

            margin-top: 20px;
        }
    }

    &.booking-counntry-cart {
        #mcalendar-booking-cart {
            padding: 15px 15px ($spacer * .5);
            display: block;
            position: relative;

            @include break-from(medium) {
                display: none;
            }

            #mdatepicker {
                top: 92px;
            }

            .go-places {
                margin-bottom: $spacer * .3;
            }

            .icalendar {
                margin-bottom: $spacer * .3;
                position: relative;

                &:before {
                    content: " ";
                    background-image: url(../images/icons8-calendar-96.png);
                    width: 21px;
                    height: 21px;
                    position: absolute;
                    bottom: 9px;
                    right: 5px;
                }

                .form-control {
                    background: $white;
                }
            }

            .book-now {
                button {
                    width: 100%;
                    border: 0;
                    padding: 6px;
                    background: $white;
                    color: $gray-150;
                    font-weight: 600;
                    border-radius: 3px;
                    border: 1px solid #ced4da;

                    &.active {
                        
                        background: $yellow;
                        border-color: $yellow;
                        color: $black;
                    }
                }
            }
        }
    }

    &.app-download {
        position: fixed;
        z-index: 99999;
        bottom: 0;
        width: 100%;
        background: #fff;
        border-radius: 10px 10px 0px 0px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        display: none;

        @include break-from(medium) {
            display: none !important;
        }

        .content {
            padding: $spacer 0.5rem ($spacer * .5);
            background: rgba(147, 75, 219, .6);
            border-radius: 10px 10px 0px 0px;
        }

        .half-circle {
            width: 90px;
            height: 46px;
            background-color: #c093eb;
            border-top-left-radius: 75px;
            border-top-right-radius: 75px;
            border-bottom: 0;
            margin: 0 auto -40px auto;
            top: -69px;
            position: relative;

            img {
                width: 75%;
            }
        }

        .heading {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            padding: 0 45px;
        }

        .body {
            font-size: 11px;
            line-height: 13px;
            padding: 0 40px;
        }

        .btn {
            font-size: 11px;
            line-height: 13px;
            border-radius: 5px;
            text-transform: unset;
        }

        .close-app-download {
            color: #121212;
            font-size: 11px;
            line-height: 13px;
        }

        .not-now {
            margin-top: 1.2rem;
        }

        .iOS, .android {
            display: none;
        }
    }
}

#mdatepicker {
    flex-direction: column;
    z-index: 11;
    display: none;
    position: absolute;
    left: 50%;
    top: -115px;
    text-align: center;
    transform: translate(-50%);
    width: 100%;
    // max-width: 18.5em;
    max-width: calc(100% - 20px);
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgba($black, 0.2);

    .ui-datepicker.ui-widget.ui-widget-content {
        margin: 0 auto;
        text-align: center;
        border-radius: 20px;
        width: 100% !important;
        // max-width: 18.5em;
        max-width: 100%;
        box-shadow: none;
    }
}

.page-template-gticket-page {
    .mobile {
        &.booking-counntry-search {
            padding: 0;

            h2 {
                a {
                    color: #f2d149;
                    background: #000;
                }
            }
        }
    }
}

.datepicker-buttons {

    display: flex;

    button {

        font-size: rem(14px);
        flex-grow: 1;
        padding: 10px;
        border: none;
        background: $light-gray;
        font-weight: 600;
        color: $default-color;

        &.cancel {

            border-top-left-radius: 10px;
        }

        &.submit {

            border-top-right-radius: 10px;
            background: $yellow;
        }
    }
}
