#datepicker {
  z-index: 11;
  display: none;
  position: absolute;
  left: 18px;
  right: 0;
  top: 160px;
  text-align: center;

  @include break-from( medium) {
    position: absolute;
    top: 6em;
    right: 0;
    left: inherit;
  }
}

.ui-datepicker.ui-widget.ui-widget-content:not(.ui-datepicker-inline) {
  left: 0 !important;
  right: 0 !important;
  margin: 10px auto !important;
  width: 21em !important;
  box-shadow: 0px 0px 1px $gray;
}

.ui-datepicker-header,
#ui-datepicker-div .ui-state-default,
.ui-widget-content .ui-state-default {
    background: transparent;
    border: none;
    text-align: center;
  }

  #ui-datepicker-div {
    position: absolute;
    border: none !important;
    padding: 20px !important;
    border-radius: 20px !important;
  }

  .ui-datepicker-title span {
    font-size: $font-size-base * 0.875;
    text-transform: capitalize;
    font-weight: 700;
  }

  .ui-datepicker-calendar span {
    font-weight: normal;
  }

  .ui-datepicker select {
    &.ui-datepicker-month, &.ui-datepicker-year {
      width: 50%;
      border: 0 !important;
      background: no-repeat;
    }
  }

  .ui-datepicker .ui-datepicker-prev,
  .ui-datepicker .ui-datepicker-next {
    &:hover {
      background: none;
      border: 0;
    }
  }
  .ui-datepicker-current-day {
    .ui-state-active {
      color: $black;
    }
  }

.ui-datepicker-hover a,
.ui-datepicker-hover:after {
  background-color: lighten(#C4C4C4, 15%) !important;
  color: $black !important;
}

.ui-datepicker-select a {
  background-color: darken(#C4C4C4, 15%);
  color: $black !important;
}

.ui-datepicker {
  background: $white;
	padding: 30px 10px;

  .ui-icon-circle-triangle-e {
    background-position: -35px 0;
  }

  .ui-icon-circle-triangle-w {
    background-position: -95px 0;
  }

	&:after {
		display: block;
		content: '';
		height: 0;
		overflow: hidden;
		clear: both;
	}

  &.ui-widget.ui-widget-content {
    border-radius: 40px;
    width: 18.5em !important;
    box-shadow: 0 0 10px 1px rgba($black, 0.1);

    @include break-from( medium) {
      width: 38em !important;
      border: 0 !important;
    }
  }

  .ui-datepicker-hover {

    &.ui-checkin.last {

      &:after {

        width: 50% !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:before {

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.ui-checkin.first {

      &:after {

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.ui-checkin.last.first {

      &:after {

        opacity: 0;
      }
    }

    &.first {

      &:before,
      &:after {

        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }

      a {

        border-top-left-radius: 30px !important;
        border-bottom-left-radius: 30px !important;
      }
    }

    &.last {

      &:before,
      &:after {

        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }

      a {

        border-top-right-radius: 30px !important;
        border-bottom-right-radius: 30px !important;
      }
    }

    + .ui-checkin {

      &:after {

        left: 0 !important;
        width: 100% !important;
      }
    }
  }


  .ui-datepicker-group {
    float: inherit;
    width: 100%;
    padding: 0 10px;

    @include break-from( medium) {
      float: left;
      width: 50%;
    }
  }

	.ui-datepicker-header {
		color: $black;

		text-align: center;
		text-transform: uppercase;
    padding-bottom: 8px;

		.ui-datepicker-prev, .ui-datepicker-next {
			background: transparent;
			cursor: pointer;
			height: 15px;
			overflow: hidden;
			position: absolute;
			text-indent: -100em;
			margin-top: 4px;
			user-select: none;
			width: 18px;

      &:hover {
        background-color: transparent;
      }

			&.ui-state-disabled {
				opacity: 0.3;
			}
		}
		.ui-datepicker-prev {
			left: 25px;
      background: url("../images/arrow-left.png") no-repeat center;
      background-size: 100%;
		}
		.ui-datepicker-next {
			right: 25px;
      background: url("../images/arrow-left.png") no-repeat center;
      background-size: 100%;
      @include transform(rotate(180deg));
		}
	}

	.ui-datepicker-calendar {
    border-collapse: collapse;
		font-size: $font-size-base * 0.875;
		cursor: default;
    width: 100%;

    @include break-from( medium ) {
      margin-top: 5px;
    }

		th, td {
			text-align: center;
			vertical-align: middle;
			width: 33px;
			height: 35px;
			line-height: 20px;
      padding: 0;
      z-index: 1;
		}

		th {
			color: #62748c;

      span {

        font-weight: 500;
      }
		}

		td {
			color: #8d8d8d;
      padding: 2px 0;
      position: relative;

      &:before,
      &:after{
        bottom: 2px;
        content: '';
        left: 0;
        top: 2px;
        position: absolute;
        width: 100%;
        z-index: -1;
      }

      &:first-child:before {
        border-radius: $cellHeight/2 0 0 $cellHeight/2;
      }

      &:last-child:before {
        border-radius:  0 $cellHeight/2 $cellHeight/2 0;
      }

			&.ui-datepicker-other-month, &.ui-state-disabled {
				color: #d0d0d0;
        font-weight: 400;
        font-size: 14px;

        span {

          color: #d0d0d0;
        }
			}
		}

		a, span {
			display: inline-block;
			padding: 6px;
      width: 32px;
			text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      color: $black;
		}

		.ui-datepicker-highlight:after {
			background: $black;
		}

    .ui-checkin:after {
      left: 50%;
      width: 50%;
    }

    .ui-checkout:after {
      right: 50%;
      width: 50%;
    }

    .ui-datepicker-highlight a {
      color: $white;
    }
    .ui-checkin a,
    .ui-checkout a {
      color: $white !important;
      background-color: #121212 !important;
      border-radius: 50% !important;
    }
    .ui-checkin.ui-datepicker-hover .ui-state-hover {
      border-radius: 50%;
    }
    .ui-datepicker-hover .ui-state-hover {
      border-radius: 0 50% 50% 0;
    }
		.ui-datepicker-today a {
			background: $primary;
      border-radius: 50%;
			color: $white;
		}
	}
}

.ui-datepicker-trigger {
	float: left;
	height: 15px;
	margin: 13px 0 0 -35px;
	overflow: hidden;
	text-indent: -100em;
	width: 16px;
}