.popup-hotdeals {

  position: fixed;
  bottom: 115px;
  right: 20px;
  width: 258px;
  padding: 15px;
  background: $yellow;
  border-radius: 25px;
  text-align: center;
  box-shadow: 0 0 5px 1px rgba($gray-900, 0.3);
  cursor: pointer;
  @include animation(bounceInRight 1s 1);  
  z-index: 991;

  span {

    display: block;
    font-size: rem(12px);
  }

  strong {

    position: relative;
    display: inline-block;
    font-size: rem(20px);
  }

  .btn-close {

    font-size: rem(12px);
    position: absolute;
    top: -12px;
    right: -8px;
    border: none;
    background: none;
  }

  .pop-hd-inner {

    position: relative;
  }

  &:not(.show) {

    display: none;
  }

  @include media-breakpoint-down(xs) {

    right: auto;
    left: 15px;
    bottom: 100px;
  }
  
}


@include keyframes(bounceInRight) {
  0%,60%,75%,90%,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  0%{
      opacity:0;
      -webkit-transform:translate3d(3000px,0,0) scaleX(3);
      transform:translate3d(3000px,0,0) scaleX(3)
  }
  60%{
      opacity:1;
      -webkit-transform:translate3d(-25px,0,0) scaleX(1);
      transform:translate3d(-25px,0,0) scaleX(1)
  }
  75%{
      -webkit-transform:translate3d(10px,0,0) scaleX(.98);
      transform:translate3d(10px,0,0) scaleX(.98)
  }
  90%{
      -webkit-transform:translate3d(-5px,0,0) scaleX(.995);
      transform:translate3d(-5px,0,0) scaleX(.995)
  }
  to{
      -webkit-transform:translateZ(0);
      transform:translateZ(0)
  }
}